<template>
  <div
    class="w-full max-w-[690px] px-md md:px-md min-w-[245px] flex flex-col bg-primary-lighter py-sm md:py-md relative"
  >
    <label :for="inputFieldId" class="font-bold leading-2xs mb-[7px]">
      {{ t('addressSuggestions.input.label') }}</label
    >
    <div class="relative w-full">
      <label
        class="box-border w-full bg-white leading-[22px] py-xs px-sm rounded-alt-full flex flex-row gap-xs font-deco-alt text-lg text-text-light"
        :class="{
          '!rounded-none !rounded-t-alt-3xl': showSuggestions,
        }"
      >
        <input
          :id="inputFieldId"
          ref="inputField"
          v-model="userInput"
          class="w-full p-0 border-none text-text-base overflow-elipsis focus:ring-transparent"
          :placeholder="t('addressSuggestions.input.placeholder')"
          role="presentation"
          autocomplete="off"
          autofill="off"
          @input="
            (event: any) =>
              handleInputChange((event.target as HTMLInputElement).value)
          "
          @keydown.enter.prevent.stop="
            handleSelectSuggestion(state.suggestions[dropdownSelectedField])
          "
          @keydown.down.prevent.stop="() => selectDropdownField(0)"
        />

        <div
          v-if="userInput"
          class="relative cursor-pointer text-grey-dark seperator"
          @click="clearInput()"
        >
          <Icon icon-class="far xmark" />
        </div>
        <div class="cursor-pointer text-grey-dark">
          <Icon
            icon-class="far magnifying-glass"
            class="text-xl text-text-light"
          />
        </div>
      </label>
      <Popup trigger-class="top-[13px] right-[45px] ">
        <template #trigger>
          <FaIcon
            icon-class="fad fa-circle-info"
            style="
              --fa-primary-color: var(--thm-white-base);
              --fa-secondary-color: var(--thm-status-warn-base);
              --fa-secondary-opacity: 1;
            "
          />
        </template>
        <template #content>{{ t('addressSuggestion.info') }}</template>
      </Popup>
    </div>
    <div class="relative w-full overflow-visible z-510">
      <div class="mt-[1px]"></div>
      <div
        v-if="showSuggestions"
        class="absolute flex flex-col w-full bg-white shadow-md pl-sm pt-xs pr-md pb-md rounded-b-alt-3xl"
      >
        <div
          v-if="
            state.error.filteredInput ||
            (!state.suggestions.length && userInput?.length > minInputLength)
          "
          class="flex flex-row items-center text-sm flex-nowrap"
        >
          <Icon
            v-if="state.error.filteredInput || !state.loading.waiting"
            icon-class="fad fa-circle-info"
            style="
              --fa-primary-color: var(--thm-text-base);
              --fa-secondary-color: var(--thm-status-warn-base);
              --fa-secondary-opacity: 1;
            "
            class="mr-sm"
          />
          <div v-if="state.error.filteredInput">
            {{
              t('suggestions.input.filter.template', {
                filter: t(state.error.filteredInput),
              })
            }}
          </div>
          <div v-else-if="!state.loading.waiting">
            {{ t('suggestions.input.noResult') }}
          </div>
        </div>
        <div
          v-for="(suggestion, index) of state.suggestions"
          :id="inputFieldId + index"
          :key="index"
          ref="suggestionsFields"
          tabindex="0"
          class="flex flex-row items-center cursor-pointer flex-nowrap hover:bg-primary-lighter focus:outline-none focus:bg-primary-lighter focus:px-xs hover:px-xs hover:-mx-xs focus:-mx-xs py-2xs"
          @click="async () => await handleSelectSuggestion(suggestion)"
          @keyup.enter.prevent.stop="
            handleSelectSuggestion(state.suggestions[dropdownSelectedField])
          "
          @keydown.up.prevent.stop="
            () => selectDropdownField(dropdownSelectedField - 1)
          "
          @keydown.down.prevent.stop="
            () =>
              selectDropdownField(
                dropdownSelectedField !== null ? dropdownSelectedField + 1 : 0,
              )
          "
        >
          <Icon icon-class="far location-dot" class="mr-sm" />
          <div>
            <span
              v-for="(
                part, suggestionSubstringIndex
              ) of getSuggestionWithHighlightAsStringArray(
                suggestion,
                textCutOff,
              )"
              :key="suggestionSubstringIndex"
              :class="{ 'font-bold': part.highlighted }"
            >
              {{ part.text }}
            </span>
            <span v-if="suggestion.text.length > textCutOff">...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@/complib';
import type { Result as SuggestionsResult } from '@/server/api/[site]/user/account/suggestAddresses.post';
import useAddressSuggestions from './composables/useAddressSuggestions';
import { v4 as uuidv4 } from 'uuid';
import Popup from '~~/src/components/formFields/components/formInfoPopup.vue';
import FaIcon from '~~/src/components/fa-icon.vue';

const props = defineProps({
  formkitAddressFields: {
    type: Object as PropType<
      Parameters<typeof useAddressSuggestions>[0]['fillFormkitAddressFields']
    >,
    required: false,
    default: () => null,
  },
  filteredTerms: {
    type: Array as PropType<
      Parameters<typeof useAddressSuggestions>[0]['filterInputByTerms']
    >,
    required: false,
    default: () => [
      {
        description: 'suggestions.input.filter.packingstations',
        values: ['packsta', 'packings'],
        type: 'startsWith',
      },
    ],
  },
});

const minInputLength = 3;
const textCutOff = 45;
const { t } = useTrans();
const emit = defineEmits<{
  (e: 'address-chosen', value: any): void;
}>();

const userInput = ref('');
const inputFieldId = uuidv4();
const activeElement = useActiveElement();

const inputField = ref<HTMLInputElement | null>(null);
const suggestionsFields = ref<HTMLElement[]>([]);
const dropdownSelectedField = ref<number | null>(null);

const inputIsFocused = computed(() =>
  activeElement.value.id.startsWith(inputFieldId),
);

const showSuggestions = computed(() => {
  return (
    inputIsFocused.value &&
    (state.error.filteredInput ||
      userInput.value?.length > minInputLength ||
      state.suggestions.length)
  );
});

watch(
  () => activeElement.value,
  (val) => {
    if (val.id > inputFieldId && val.id.startsWith(inputFieldId)) {
      dropdownSelectedField.value = parseInt(val.id.slice(inputFieldId.length));
    }
  },
);

const {
  state,
  getSuggestions,
  selectSuggestion,
  clearSuggestions,
  getSuggestionWithHighlightAsStringArray,
} = useAddressSuggestions({
  fillFormkitAddressFields: props.formkitAddressFields,
  filterInputByTerms: props.filteredTerms,
});

function selectDropdownField(index: number) {
  if (state.suggestions.length > index && index >= 0) {
    dropdownSelectedField.value = index;
  } else {
    dropdownSelectedField.value = null;
  }
}

watch(
  () => dropdownSelectedField.value,
  (val) => {
    if (val !== null) {
      suggestionsFields.value[val]?.focus({ preventScroll: true });
    } else {
      inputField.value?.focus({ preventScroll: true });
    }
  },
);

function handleInputChange(val: string) {
  userInput.value = val;
  getSuggestions(val);
  dropdownSelectedField.value = null;
}

function clearInput() {
  userInput.value = '';
  dropdownSelectedField.value = null;
  clearSuggestions();
}

async function handleSelectSuggestion(
  suggestion: SuggestionsResult['suggestions'][0],
) {
  if (!state.loading.suggestions) {
    await selectSuggestion(suggestion);
    if (state.selectedSuggestion.details) {
      emit('address-chosen', suggestion);
      clearInput();
    }
  }
}
</script>
