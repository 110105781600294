import type {
  Result as SuggestionsResult,
  PostBody as SuggestionPostBody,
} from '@/server/api/[site]/user/account/suggestAddresses.post';
import type {
  Result as FullAddressResult,
  PostBody as FullAddressPostBody,
} from '@/server/api/[site]/user/account/fullAddressFromPlaceId.post';
import { useSecureSessionPost } from '@/composables/dataFetching/genericFetchers';
import { getNode } from '@formkit/core';

export type FormKitAddressFieldInput = {
  input: {
    [key in keyof FullAddressResult['address']]?: string;
  };
  fieldNames: {
    [key in keyof FullAddressResult['address']]?: string;
  };
};

/**
 * @param options
 * @param options.debounceTime Suggestion fetch debounce time | default: 500
 * @param options.inputLengthThreshold Minimum length of the input before suggestions are fetched | default: 3
 * @param options.fillFormkitFields On Address select tries to fill formkit fields | default: null
 * @param options.filterResultsByType Filters _results_ by the given types | default: []
 * @param options.filterInputByTerms Filters _input_ by the given terms and sets error state if matches found | default: []
 * @returns state - reactive object containing the state of the suggestions
 * @returns getSuggestions - function to fetch suggestions
 * @returns selectSuggestion - function to select a suggestion (loads details)
 * @returns clearSuggestions - function to clear the suggestions & error-states
 * @returns getSuggestionWithHighlightAsStringArray - helper function to get the suggestion as an array with highlighted parts
 */
export default function useAddressSuggestions(options?: {
  debounceTime?: number;
  inputLengthThreshold?: number;
  fillFormkitAddressFields?: FormKitAddressFieldInput['fieldNames'];
  filterResultsByType?: Array<
    SuggestionsResult['suggestions'][0]['types'] | Array<string>
  >;
  filterInputByTerms?: Array<{
    description: string;
    values: Array<string>;
    type: 'startsWith' | 'includes';
  }>;
}) {
  const debounceTime = options?.debounceTime ?? 500;
  const inputLengthThreshold = options?.inputLengthThreshold ?? 3;
  const filterResultsByType = options?.filterResultsByType ?? [];
  const filterInputByTerms = options?.filterInputByTerms ?? [];
  const removals = useTrans().t('addressSuggestions.name1Removals').split('; ');

  const state = reactive({
    previousResponseId: null as string | null,
    suggestions: [] as SuggestionsResult['suggestions'],
    selectedSuggestion: {
      suggestion: null as SuggestionsResult['suggestions'][0],
      details: null as any,
    },
    error: {
      details: false,
      suggestions: false,
      formkitFieldFill: false,
      filteredInput: null as string | null,
    },
    loading: {
      details: false,
      suggestions: false,
      waiting: true,
    },
  });

  /**
   * @summary Debounced fetch of suggestions based on the input
   * @info Influenced by the `debounceTime` and `inputLengthThreshold` options
   */
  async function getSuggestions(input: string) {
    state.loading.waiting = true;
    getSuggestionsDebounced(input);
  }

  const getSuggestionsDebounced = useDebounceFn(async (input: string) => {
    const inputValidity = handleInputValidity(input);
    if (!inputValidity) return;

    state.error.filteredInput = null;
    state.suggestions = [];
    state.loading.suggestions = true;
    try {
      const postbody: SuggestionPostBody = {
        address: input,
        previousResponseId: state.previousResponseId,
      };
      const reqResult = await useSecureSessionPost<SuggestionsResult>(
        `/api/${useSiteIdent()}/user/account/suggestAddresses`,
        postbody,
      );
      handleSuggestionsResponse(reqResult);
      state.error.suggestions = false;
    } catch (e) {
      state.error.suggestions = true;
      throw e;
    } finally {
      state.loading.suggestions = false;
      state.loading.waiting = false;
    }
  }, debounceTime);

  function handleInputValidity(input: string) {
    if (input?.length <= inputLengthThreshold) {
      state.error.filteredInput = null;
      state.suggestions = [];
      return false;
    }

    for (const term of filterInputByTerms) {
      if (
        term.values.some((value) => {
          return term.type === 'startsWith'
            ? input.toLowerCase().startsWith(value)
            : input.toLowerCase().includes(value);
        })
      ) {
        state.error.filteredInput = term.description;
        state.suggestions = [];
        return false;
      }
    }

    return true;
  }

  function handleSuggestionsResponse(response: SuggestionsResult) {
    if (response.responseId) {
      state.previousResponseId = response.responseId;
    }
    state.suggestions = response.suggestions;
    state.suggestions = state.suggestions.slice(0, 6);
    if (filterResultsByType.length) {
      for (const type of filterResultsByType) {
        state.suggestions = state.suggestions.filter((suggestion) =>
          suggestion.types.includes(type as any),
        );
      }
    }
  }

  /**
   * @summary Fetches the details of the selected suggestion & sets the state accordingly
   */
  async function selectSuggestion(
    suggestion: SuggestionsResult['suggestions'][0],
  ) {
    if (!state.loading.details) {
      state.loading.details = true;
      state.selectedSuggestion.suggestion = suggestion;
      await getPlaceDetails(suggestion.placeId);
      if (options?.fillFormkitAddressFields) {
        fillFormkitAddressFields(
          state.selectedSuggestion.details.address,
          options.fillFormkitAddressFields,
        );
      }
      state.loading.details = false;
    }
  }

  async function getPlaceDetails(placeId: string) {
    let result: FullAddressResult | null = null;
    try {
      const postBody: FullAddressPostBody = {
        placeId,
        previousResponseId: state.previousResponseId,
      };
      result = await useSecureSessionPost<FullAddressResult>(
        `/api/${useSiteIdent()}/user/account/fullAddressFromPlaceId`,
        postBody,
      );
      state.selectedSuggestion.details = result;
      state.error.details = false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      state.error.details = true;
    }
  }

  /**
   * @summary Clears the suggestions, selected suggestion & error-states
   */
  function clearSuggestions() {
    state.suggestions = [];
    state.selectedSuggestion.suggestion = null;
    state.selectedSuggestion.details = null;
    state.error.formkitFieldFill = false;
    state.error.details = false;
    state.error.suggestions = false;
  }

  /**
   * @returns An array of {text: string, highlighted: boolean} objects
   */
  function getSuggestionWithHighlightAsStringArray(
    suggestion: SuggestionsResult['suggestions'][0],
    maxLength = 9999,
  ): Array<{ text: string; highlighted: boolean }> {
    const result: Array<{ text: string; highlighted: boolean }> = [];
    let currentIndex = 0;
    let totalLength = 0;

    for (const { start, end } of suggestion.highlightedSubstrings) {
      if (
        currentIndex < start &&
        addText(suggestion.text.slice(currentIndex, start), false)
      ) {
        return result;
      }

      if (addText(suggestion.text.slice(start, end), true)) {
        return result;
      }

      currentIndex = end;
    }

    if (currentIndex < suggestion.text.length && totalLength < maxLength) {
      addText(suggestion.text.slice(currentIndex), false);
    }

    function addText(text: string, highlighted: boolean) {
      const remainingLength = maxLength - totalLength;
      const sliceLength = Math.min(text.length, remainingLength);
      result.push({ text: text.slice(0, sliceLength), highlighted });
      totalLength += sliceLength;
      return totalLength >= maxLength;
    }

    return result;
  }

  /**
   * @summary Fills the given formkit fields with the given input
   * - Silently errors on exception and does not throw
   */
  function fillFormkitAddressFields(
    input: FormKitAddressFieldInput['input'],
    fieldNames: FormKitAddressFieldInput['fieldNames'],
  ) {
    try {
      for (const [key] of Object.entries(fieldNames)) {
        const field = getNode(fieldNames[key as keyof typeof fieldNames]);
        if (field) {
          if ((key as keyof typeof fieldNames) === 'name1') {
            field.input(
              removeMatchingWords(input[key as keyof typeof input], removals),
            );
          } else {
            field.input(input[key as keyof typeof input]);
          }
        }
      }
    } catch (e) {
      state.error.formkitFieldFill = true;
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return {
    state,
    selectSuggestion,
    getSuggestions,
    clearSuggestions,
    getSuggestionWithHighlightAsStringArray,
  };
}

/**
 * @summary removals get removed from target, whitespace gets trimmed
 * @example ('eins zwei drei vier fünf', ['zwei', 'fünf']) => 'eins drei vier'
 */
function removeMatchingWords(target: string, removals: string[]) {
  const removalRegex = new RegExp(
    removals.map((word) => `\\b${word}\\b`).join('|'),
    'g',
  );
  return target.replace(removalRegex, '').replace(/\s+/g, ' ').trim();
}
