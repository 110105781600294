<template>
  <div>
    <slot name="headline" />
    <div
      class="flex flex-col max-w-full border-solid cursor-pointer rounded-alt-md border-1 border-grey-dark p-sm"
    >
      <div class="flex flex-row flex-nowrap">
        <Checkbox :model-value="selected" />
        <div class="flex flex-col">
          <div>
            <span
              :class="{
                [getHighlightClass(highlightType)]:
                  addressValidationData['street'].highlighted,
              }"
              >{{ addressValidationData['street'].value }}</span
            >&nbsp;
            <span
              :class="{
                [getHighlightClass(highlightType)]:
                  addressValidationData['number'].highlighted,
              }"
              >{{ addressValidationData['number'].value }}</span
            >
          </div>
          <div>
            <span
              :class="{
                [getHighlightClass(highlightType)]:
                  addressValidationData['zipcode'].highlighted,
              }"
              >{{ addressValidationData['zipcode'].value }}</span
            >&nbsp;
            <span
              :class="{
                [getHighlightClass(highlightType)]:
                  addressValidationData['city'].highlighted,
              }"
              >{{ addressValidationData['city'].value }}</span
            >
          </div>
          <slot name="belowAddress" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Result } from '@/server/api/[site]/user/account/validateAddress.post';
import { Checkbox } from '@/complib';

type OriginalFormat = Result['addresses'][0]['originalFormat'];
type AddressValidationData = {
  [Property in keyof OriginalFormat]: {
    value: OriginalFormat[Property];
    highlighted: boolean;
  };
};

const props = defineProps({
  addressValidationData: {
    type: Object as PropType<AddressValidationData>,
    required: true,
  },
  highlightSuggestions: {
    type: Boolean,
    default: false,
  },
  highlightType: {
    type: String as PropType<'positive' | 'negative'>,
    default: 'positive',
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

function getHighlightClass(highlightType: (typeof props)['highlightType']) {
  return highlightType === 'positive'
    ? 'font-bold text-status-success-dark'
    : 'font-bold text-status-danger-dark';
}
</script>
