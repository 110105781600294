// List of all invalid  words in street feld
// invalid words are the words that refer to Packing station or P.O. Box
const unvalidWords = ['Packstation', 'Postnummer', 'Postnumer', 'Postnr'];

// check if a string contain at lest one of invalid words
export function checkStreetNameForPackingStation(streetName: string) {
  const Rg = new RegExp(unvalidWords.join('|'), 'i');
  const Rg1 = /\d{9}/;
  streetName = streetName.trim().replace(/\s+/g, '');
  return Rg1.test(streetName) || Rg.test(streetName);
}
