<template>
  <div v-if="open">
    <Teleport :to="teleportTarget" :disabled="!teleportTarget">
      <dialog
        ref="dialogRef"
        :style="`z-index:${10000 + dialogId};`"
        :class="{
          'top-0 w-full h-full flex': open,
          'bg-grey-dark-opc50': open && dialogId === 0,
        }"
      >
        <div
          class="flex flex-col m-auto w-[370px] md:w-[510px] max-h-full md:max-h-full bg-white rounded-alt-2xl overflow-auto overscroll-contain"
          :style="`transform:translate(0px,${dialogId * 30}px)`"
        >
          <slot v-if="$slots.headlineReplace" name="headlineReplace" />
          <div
            v-else
            class="flex flex-row items-center w-full shrink-0 h-xl rounded-t-alt-2xl bg-primary-lightest flex-nowrap px-md"
          >
            <Headline
              :headline="t(headline)"
              level="h4"
              class="!mr-auto !ml-0"
            />

            <button
              v-show="showCloseButton"
              type="button"
              name="close"
              class="ml-auto"
              @click="() => $emit('close')"
            >
              <Icon
                icon-class="fas fa-xmark"
                class="text-lg cursor-pointer text-border-dark"
              />
            </button>
          </div>
          <div class="w-full p-md">
            <slot name="content" />
          </div>
        </div>
      </dialog>
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { Headline, Icon } from '@/complib';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  headline: {
    type: String,
    required: true,
  },
  onCloseCb: {
    type: Function,
    default: () => {},
  },
  teleportTarget: {
    type: String,
    default: 'body',
  },
});

const dialogRef = ref<any | null>(null);
const { t } = useTrans();
const htmlDialogOrder = useState('htmlDialogOrder', () => []);
const dialogId = ref(0);

watch(
  () => props.open,
  (newVal) => {
    if (newVal) {
      dialogRef.value?.showModal();
      dialogId.value = htmlDialogOrder.value.length;
      htmlDialogOrder.value.push(dialogId.value);
    } else {
      dialogRef.value?.close();
      htmlDialogOrder.value = htmlDialogOrder.value.filter(
        (id) => id !== dialogId.value,
      );
      props.onCloseCb();
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  htmlDialogOrder.value = htmlDialogOrder.value.filter(
    (id) => id !== dialogId.value,
  );
});

defineEmits(['close']);
</script>
