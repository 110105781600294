<template>
  <ValidationRow
    name="streetNumber"
    combined-validation-message="registration.emptyError.billingAddress.streetNumber"
  >
    <TextInput
      id="registrationStreet"
      v-model="street"
      :name="`street${shippingSuffix}`"
      label="registration.billingAddress.street"
      placeholder="registration.billingAddress.placeholder.street"
      width="basis-9/12"
      required-message="registration.emptyError.billingAddress.street"
      :maxlength="40"
    />
    <TextInput
      id="registrationHouseNumber"
      v-model="number"
      :name="`houseNumber${shippingSuffix}`"
      label="registration.billingAddress.number"
      placeholder="registration.billingAddress.placeholder.number"
      width="basis-3/12"
      required-message="registration.emptyError.billingAddress.number"
      :maxlength="9"
    />
  </ValidationRow>
  <div v-if="validatePackingStation && isPackingStation" class="mb-xs">
    <NotificationBar
      :type="NotificationType.WARN"
      description="myAccount.deliveryTopackingStationsHint"
      classes="!px-xs !gap-sm mt-sm"
    />
  </div>

  <ValidationRow
    name="zipCity"
    combined-validation-message="registration.emptyError.billingAddress.zipCity"
  >
    <TextInput
      id="registrationZip"
      v-model="zip"
      :name="`zip${shippingSuffix}`"
      label="registration.billingAddress.zip"
      placeholder="registration.billingAddress.placeholder.zip"
      width="basis-3/12"
      required-message="registration.emptyError.billingAddress.zip"
      matches-message="registration.emptyError.billingAddress.zip"
      :validation="zipCodeValidation"
      :maxlength="20"
    />
    <TextInput
      id="registrationCity"
      v-model="city"
      :name="`city${shippingSuffix}`"
      label="registration.billingAddress.city"
      placeholder="registration.billingAddress.placeholder.city"
      width="basis-9/12"
      required-message="registration.emptyError.billingAddress.city"
      :maxlength="40"
    />
  </ValidationRow>
</template>
<script setup lang="ts">
import { TextInput, NotificationBar, NotificationType } from '@/complib';
import ValidationRow from '@/components/formFields/validationRow/validationRow.vue';
import { useZipCodeValidation } from '@/components/formFields/composables/useZipCodeValidation';
import { checkStreetNameForPackingStation } from '~/helpers/validations';

const props = defineProps({
  city: {
    type: String,
    required: false,
    default: '',
  },
  street: {
    type: String,
    required: false,
    default: '',
  },
  number: {
    type: String,
    required: false,
    default: '',
  },
  zip: {
    type: String,
    required: false,
    default: '',
  },
  isShippingAddress: {
    type: Boolean,
    required: false,
    default: false,
  },
  validatePackingStation: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const shippingSuffix = props.isShippingAddress ? 'Shipping' : '';

const emits = defineEmits<{
  (e: 'update:city', value: string): void;
  (e: 'update:street', value: string): void;
  (e: 'update:number', value: string): void;
  (e: 'update:zip', value: string): void;
}>();

const street = ref(props.street);
const number = ref(props.number);
const zip = ref(props.zip);
const city = ref(props.city);

const zipCodeValidation = useZipCodeValidation();
const isPackingStation = computed(() =>
  checkStreetNameForPackingStation(street.value),
);

watch(street, (newValue) => {
  emits('update:street', newValue);
});
watch(number, (newValue) => {
  emits('update:number', newValue);
});
watch(zip, (newValue) => {
  emits('update:zip', newValue);
});
watch(city, (newValue) => {
  emits('update:city', newValue);
});
</script>
