<template>
  <SimpleHtmlDialog
    :open="open"
    headline="addressValidation.choice.dialogHeadline"
    :teleport-target="null"
    @close="() => selectAndSaveAddress(0)"
  >
    <template #content>
      <div v-if="!incompleteData && open" class="flex flex-col w-full">
        <div v-if="!noSuggestion">
          {{ t('addressValidation.choice.description') }}
        </div>
        <div v-else>
          {{ t('addressValidation.choice.noSuggestion') }}
        </div>
        <AddressField
          v-if="!noSuggestion"
          class="mt-md"
          :address-validation-data="addressFields[1]"
          :highlight-suggestions="highlightSuggestions"
          :highlight-type="'positive'"
          :selected="selectedAddress === 1"
          @click="selectAddress(1)"
        >
          <template #headline>
            <div class="font-bold">
              {{ t('addressValidation.choice.ourSuggestion') }}
            </div>
          </template>
        </AddressField>

        <AddressField
          class="mt-sm"
          :address-validation-data="addressFields[0]"
          :highlight-suggestions="highlightSuggestions"
          :highlight-type="'negative'"
          :selected="selectedAddress === 0"
          @click="selectAddress(0)"
        >
          <template #headline>
            <div class="font-bold">
              {{ t('addressValidation.choice.userInput') }}
            </div>
          </template>
          <template #belowAddress>
            <div
              class="flex gap-2 cursor-pointer place-items-center text-primary-base"
              @click="() => selectAndSaveAddress(0)"
            >
              <Icon icon-class="fal fa-pen" />
              <span class="anim__underline-link anim-link">
                {{ t('user.account.addresses.edit') }}
              </span>
            </div>
          </template>
        </AddressField>
        <NotificationBar
          :type="NotificationType.WARN"
          classes="!px-xs !gap-sm mt-md"
          ><div class="!py-xs">
            {{ t('addressValidation.choice.hint') }}
          </div>
        </NotificationBar>
        <BasicButton
          full-width
          class="mt-md sm:!w-full"
          btn-class="sm:!w-full"
          :label="t('addressValidation.choice.selectButton')"
          @click="saveAddress()"
        >
        </BasicButton>
      </div>
    </template>
  </SimpleHtmlDialog>
</template>

<script lang="ts" setup>
import type { Result } from '@/server/api/[site]/user/account/validateAddress.post';
import AddressField from './components/addressField.vue';
import {
  SimpleHtmlDialog,
  NotificationBar,
  NotificationType,
  BasicButton,
  Icon,
} from '@/complib';

type OriginalFormat = Result['addresses'][0]['originalFormat'];
type ValidatedFormat = Result['addresses'][0]['validatedFormat'];
type AddressFieldProp = InstanceType<
  typeof AddressField
>['$props']['addressValidationData'];

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  addressValidationData: {
    type: Object as PropType<Omit<Result, 'responseId'>>,
    required: true,
  },
  highlightSuggestions: {
    type: Boolean,
    default: false,
  },
});

const { t } = useTrans();
const dialogRef = ref<any | null>(null);
const selectedAddress = ref(0);

const incompleteData = computed(() => {
  return props.addressValidationData?.addresses?.length < 2;
});
const noSuggestion = computed(() => {
  return !props.addressValidationData?.isComplete;
});

const addressKeys: (keyof OriginalFormat)[] = [
  'countryId',
  'city',
  'zipcode',
  'street',
  'number',
];

/**
 * Compares the original and validated address and returns the fields that have been corrected or inferred.
 */
const highlightedFields = computed(() => {
  if (!props.highlightSuggestions || incompleteData.value) return [];

  const highlightedFields = [];
  for (const key of addressKeys) {
    const add =
      props.addressValidationData.addresses[1].validatedFormat[
        key as keyof ValidatedFormat
      ];
    if (
      add.validationInfo.inferred ||
      add.validationInfo.spellCorrected ||
      add.validationInfo.replaced
    ) {
      highlightedFields.push(key);
    }
  }
  return highlightedFields as (keyof ValidatedFormat)[];
});

/**
 * Creates a format that can be used in the AddressField component.
 */
const addressFields = computed<AddressFieldProp[]>(() => {
  if (incompleteData.value) return [];

  const arr = [{}, {}] as AddressFieldProp[];
  for (const key of addressKeys) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = {
        ...arr[i],
        [key]: {
          value: props.addressValidationData.addresses[i].originalFormat[key],
          highlighted: highlightedFields.value.includes(key),
        },
      };
    }
  }
  return arr;
});

watch(
  () => props.open,
  (open) => {
    if (open && !incompleteData.value) {
      dialogRef.value?.showModal();
    } else {
      dialogRef.value?.close();
    }
  },
);

// Change Preselected Address-Field based on noSuggestion
watch(
  () => noSuggestion.value,
  (val) => {
    selectedAddress.value = val ? 0 : 1;
  },
);

const emit = defineEmits<{
  (e: 'address-chosen', value: OriginalFormat): void;
}>();

function selectAndSaveAddress(val: number) {
  selectAddress(val);
  saveAddress();
  selectedAddress.value = 0;
}

function selectAddress(val: number) {
  selectedAddress.value = val;
}

function saveAddress() {
  emit(
    'address-chosen',
    props.addressValidationData.addresses[selectedAddress.value].originalFormat,
  );
}
</script>
