import type { Result } from '@/server/api/[site]/user/account/validateAddress.post';
import { useSecureSessionPost } from '@/composables/dataFetching/genericFetchers';
import type { UserData } from '@/server/gateway/connections/shopware/actions/registerUser';
import { hashCode } from '~~/src/helpers/hashCode';

type AddressToValidate = Pick<
  UserData['billingAddress'],
  'countryId' | 'city' | 'zipcode' | 'street' | 'number'
>;

/**
 *
 * @returns isLoading - if the validation is currently loading
 * @returns loadingError - if the validation has encountered an (api)error
 * @returns validationResult - the result of the validation
 * @returns needsManualCheck - if the address needs manual validation
 * @returns validateAddress - function to validate an address
 * @returns resetManualCheck - function to reset the manual check
 * @returns reset - function to reset the validation
 */
export default function useValidateAddressExternal() {
  let previousResponseId = '';

  const isLoading = ref(false);
  const loadingError = ref(false);
  const validationResult = ref<Result>(null);
  const needsManualCheck = ref(false);

  /**
   * Calls the validation API to validate the given address,
   * and sets the result in the `validationResult` ref.
   */
  async function validateAddress(address: AddressToValidate): Promise<void> {
    if (
      !address ||
      isLoading.value ||
      hashCode(address) ===
        hashCode(validationResult.value?.addresses?.[0]?.originalFormat ?? {})
    )
      return;

    try {
      isLoading.value = true;
      const reqResult = await useSecureSessionPost<Result>(
        `/api/${useSiteIdent()}/user/account/validateAddress`,
        {
          address,
          previousResponseId: previousResponseId,
        },
      );
      handleResponse(reqResult);
    } catch (e) {
      setLoadingErrorState();
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  async function resetManualCheck() {
    needsManualCheck.value = false;
  }

  /**
   * Resets the validation state EXCEPT for the internal previousResponseId
   */
  function reset() {
    validationResult.value = null;
    needsManualCheck.value = false;
    loadingError.value = false;
  }

  function setLoadingErrorState() {
    validationResult.value = null;
    loadingError.value = true;
    needsManualCheck.value = false;
  }

  /*
   * Handles the response from the validation API and sets the state of the refs accordingly
   */
  function handleResponse(response: Result) {
    previousResponseId = response.responseId;
    if (response.addresses.length > 0) {
      validationResult.value = response;
      if (
        response.overallValid === false &&
        (!validationResult.value.isValid ||
          validationResult.value.hasAnyKindOfChange)
      ) {
        needsManualCheck.value = true;
      }
    } else {
      throw new Error('The Validation-API encountered an error');
    }
  }

  return {
    validateAddress,
    resetManualCheck,
    reset,
    validationResult,
    isLoading,
    loadingError,
    needsManualCheck,
  };
}
